<template>
  <v-app id="inspire">
      <v-main>
         <v-container fluid fill-height>
            <v-layout align-center justify-center>
               <v-flex xs12 md4 class="text-h2" justify-center>
                 <v-img
                    src="../assets/logo.jpg"
                  >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-card
                  class="d-flex justify-center"
                  flat
                  tile
                >
                    Próximamente
                </v-card>
               </v-flex>
            </v-layout>
         </v-container>
      </v-main>
  </v-app>
</template>
<script>

  export default {
    name: 'Home',

    components: {
    },
  }
</script>
