import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import FamiliaProducto from '../views/FamiliaProducto.vue'
import Producto from '../views/Producto.vue'
import Cliente from '../views/Cliente.vue'
import FormularioPedido from '../views/FormularioPedido.vue'
import CancelarPedido from '../views/CancelarPedido.vue'
import Login from '../views/Login.vue'
import store from '../store'
import { mapGetters } from 'vuex'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/familiaProducto',
    name: 'FamiliaProducto',
    component: FamiliaProducto,
    meta: {requiresAuth: true}
  },
  {
    path: '/producto',
    name: 'Producto',
    component: Producto,
    meta: {requiresAuth: true}
  },
  {
    path: '/cliente',
    name: 'Cliente',
    component: Cliente,
    meta: {requiresAuth: true}
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/pedido/:cliente/:categoria',
    name: 'FormularioPedido',
    component: FormularioPedido
  },
  {
    path: '/cancelar/:id',
    name: 'CancelarPedido',
    component: CancelarPedido
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  console.log("to: ", to)
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  // Requires auth & no user
  console.log("Router: ", requiresAuth, store.getters.isInitialized, !store.getters.loggedIn)
  if (requiresAuth && !store.getters.loggedIn) {
    if (store.getters.isInitialized) {
      next({ name: "Login" });
      // No requires auth and user (auth)
    } else {
      setTimeout(function(){next(to.name)}, 1000)
    }
  } else {
    // Anything else
    next();
  }
});

export default router
