<template>
         <v-container fluid>
            <v-layout>
               <v-flex xs12>
              <v-card
                class="mt-5"
              >
                <v-toolbar align-end
                >

                  <v-toolbar-title>Familias de Producto</v-toolbar-title>
                  <v-spacer />
                  <v-text-field class="pt-5"
                        v-model="buscar"
                        label="Buscar"
                      >
                  </v-text-field>
                  <v-btn icon @click="loadForm({id: '', nombre: ''}, -1)">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-toolbar>

                <v-list two-line>
                  <v-list-item-group
                  >
                    <template v-for="(item, index) in allFamiliasProducto">
                      <v-list-item :key="item.id">
                        <template>
                          <v-list-item-content>
                            <v-list-item-title v-text="item.nombre"></v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-list-item-action-text v-text="item.action"></v-list-item-action-text>

                            <v-icon @click="loadForm(item, index)">
                              mdi-pencil
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-action>
                            <v-list-item-action-text v-text="item.action"></v-list-item-action-text>

                            <v-icon @click="del(item, index)">
                              mdi-delete
                            </v-icon>
                          </v-list-item-action>
                        </template>
                      </v-list-item>

                      <v-divider
                        v-if="index < allFamiliasProducto.length - 1"
                        :key="index"
                      ></v-divider>
                    </template>
                  </v-list-item-group>
                </v-list>

                <v-row justify="center">
                <v-dialog
                  v-model="dialog"
                >
                  <v-card>
                    <v-card-title>
                      <span class="text-h6">Familia de Productos</span>
                    </v-card-title>
                    <v-card-text>
                      <v-text-field
                        v-model="nombre"
                        :counter="25"
                        label="Nombre"
                        required
                        v-on:keyup.enter="save"
                        ref="nombre"
                      >
                      </v-text-field>

                      <v-btn
                        class="mr-4"
                        @click="save"
                      >
                        submit
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-row>
            </v-card>
            </v-flex>
            </v-layout>
         </v-container>

</template>

<script>
import store from '../store'
import { mapGetters } from 'vuex'
import Vue from 'vue'

  export default {
    name: 'FamiliaProducto',

    components: {
    },

    data: () => ({
      dialog: false,
      id: "",
      nombre: "",
      buscar: "",
      update: 0,
      entityName: "familiasProducto"
    }),  

    computed: {
      allFamiliasProducto: {
        get: function () {
          return store.getters.allFamiliasProducto().filter(element => element.nombre.toLowerCase().search(this.buscar.toLowerCase()) >= 0)
        },
        set: function (newValue) {
          return;
        }
      }
    },

    methods: {
      loadForm(item, index) {
        this.id = item.id;
        this.index = index;
        this.nombre = item.nombre;
        this.dialog = true;
        console.log(this.$refs)
        let _this = this
        setTimeout(() => {_this.$refs.nombre.focus()}, 100)
      },
      save () {
        if (this.id != '') {
          this.allFamiliasProducto = [];
          store.dispatch("updateEntity", {name: this.entityName, id: this.id, data:{nombre: this.nombre}});
        } else {
          store.dispatch("addEntity", {name: this.entityName, data:{nombre: this.nombre}});
        }
        this.$set(this.allFamiliasProducto, this.index, {id:this.id, nombre:this.nombre})
        this.dialog = false;
      },
      del(item, index) {
        store.dispatch("deleteEntity", {name: this.entityName, id: item.id});
        this.refrescar()
      },
      refrescar(){
        let b = this.buscar;
        let _this = this
        this.buscar = " "
        setTimeout(function(){_this.buscar = b}, 300)
      }
    },
  }
</script>