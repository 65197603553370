<template>
  <v-app>
    <v-app-bar app v-if="loggedIn" dense>
      <v-toolbar-title @click="drawer = !drawer">Croûte</v-toolbar-title>
      <v-spacer />     
    </v-app-bar>

    <v-navigation-drawer
        v-model="drawer"
        absolute
        left
        temporary
      >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
        >
          <v-list-item>
            <v-list-item-title>
              <router-link to="/cliente">
                  <span>Clientes</span>
              </router-link>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <router-link to="/familiaProducto">
                  <span>Familias</span>
              </router-link>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <router-link to="/producto">
                  <span>Productos</span>
              </router-link>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <v-btn class="error" @click="signOut">Salir</v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import firebase from "firebase"

export default {
  name: 'App',

  data: () => ({
    drawer: false,
    main: false,
    group: null
  }),

  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({
            name: "Login"
          });
        });
    }
  },
  computed: {
    ...mapGetters(['loggedIn', 'isInitialized'])
  }
};

</script>
