<template>
         <v-container fluid>
            <v-layout justify-center>
               <v-flex  xs12 md6>

              <!-- notificacion de pedido enviado -->
              <v-snackbar v-model="enviado" :timeout="5000">
                Pedido enviado exitosamente

                <template v-slot:action="{ attrs }">
                    <v-btn
                      color="blue"
                      text
                      v-bind="attrs"
                      @click="enviado = false"
                    >
                      Nuevo Pedido
                    </v-btn>
                </template>

              </v-snackbar>

              <!-- formulario de pedido -->
              <v-card
                class="mt-5"
                v-if="!enviado"
              >
                <v-toolbar align-end>
                  <v-toolbar-title>Pedido de {{(categoria == 'panaderia' ? 'Panadería' : 'Confitería')}}</v-toolbar-title>
                </v-toolbar>

                <v-list two-line>
                    <template v-for="(familia) in familiasCategoria(categoria)">
                        <span class="ml-4 text-h6" :key="familia.id">{{familia.nombre}}</span>
                        
                        <template v-for="(producto) in productosFamilia(familia.id)">
                          <v-list-item :key="producto.id">
                            <template>
                              <v-list-item-content>
                                <v-list-item-title v-text="producto.nombre" class="ml-5"></v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-action left>
                                <v-text-field
                                  type="number"
                                  value="0"
                                  min=0
                                  v-model="pedido[producto.id]"
                                  :id="producto.id"
                                  v-on:keyup.enter="save"
                                  >
                                </v-text-field>
                              </v-list-item-action>
                            </template>
                          </v-list-item>
                        </template>
                    </template>
                </v-list>
              <v-btn variant="success" block @click="save">Enviar Pedido</v-btn>
            </v-card>
            </v-flex>
            </v-layout>
         </v-container>

</template>

<script>
import store from '../store'
import { mapGetters } from 'vuex'
import Vue from 'vue'

  export default {
    name: 'FormularioPedido',

    components: {
    },

    data: () => ({
      id: "",
      enviado: false,
      pedido: {},
      entityName: "pedidos"
    }),  

    computed: {
      allFamiliasProducto: {
        get: function () {
          return store.getters.allFamiliasProducto().filter(element => element.nombre.toLowerCase().search(this.buscar.toLowerCase()) >= 0)
        },
        set: function (newValue) {
          return;
        }
      },
      allProductos: {
        get: function () {
          return store.getters.allProductos()
        },
        set: function (newValue) {
          return;
        }
      },
      categoria: {
        get: function() {
          return this.$route.params.categoria;
        },
        set: function (newValue) {
          return;
        }
      },
      cliente: {
        get: function() {
          return this.$route.params.cliente;
        },
        set: function (newValue) {
          return;
        }
      },
      fecha: {
        get: function() {
          return Date.now();
        },
        set: function (newValue) {
          return;
        }
      }
    },

    methods: {
      save () {
        store.dispatch("addEntity", {name: this.entityName, 
          data:{
            cliente: this.cliente,
            fecha: this.fecha,
            productos: this.pedido
          }
        });
        this.enviado = true;
        this.pedido = {};
      },
      familiasCategoria(categoria){
        return store.getters.allFamiliasProducto().filter(element => categoria === undefined || element.categoria === categoria)
      },
      productosFamilia(familiaProducto){
        return store.getters.allProductos().filter(element => familiaProducto === undefined || element.familiaProducto === familiaProducto)
      },
      refrescar(){
        let b = this.buscar;
        let _this = this
        this.buscar = " "
        setTimeout(function(){_this.buscar = b}, 300)
      }
    },
  }
</script>