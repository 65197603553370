<template>
   <v-app id="inspire">
      <v-main>
         <v-container fluid fill-height>
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md4 lg4 >
                  <v-card class="elevation-12">
                     <v-toolbar dark color="primary">
                        <v-toolbar-title>Iniciar Sesión</v-toolbar-title>
                     </v-toolbar>
                     <v-card-text>
                        <v-form v-on:submit.prevent="submit">
                           <v-text-field
                              prepend-icon="mdi-account"
                              name="login"
                              label="Email"
                              type="text"
                              v-model="form.email"
                              v-on:keyup.enter="submit"
                           ></v-text-field>
                           <v-text-field
                              id="password"
                              prepend-icon="mdi-lock"
                              name="password"
                              label="Contraseña"
                              type="password"
                              v-model="form.password"
                              v-on:keyup.enter="submit"
                           ></v-text-field>
                        </v-form>
                     </v-card-text>
                     <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="submit">Aceptar</v-btn>
                     </v-card-actions>
                  </v-card>
               </v-flex>
            </v-layout>
         </v-container>
      </v-main>
   </v-app>
</template>

<script>
import firebase from "firebase";

export default {
  data() {
    return {
      form: {
        email: "",
        password: ""
      },
      error: null
    };
  },
  methods: {
    submit() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then(data => {
          this.$router.replace({ name: "Botones" });
        })
        .catch(err => {
          this.error = err.message;
        });
    }
  }
};
</script>