<template>
         <v-container fill-height fluid>
           <v-row justify="center">
                <v-col>
                  <h1 style="text-align: center;">Pedido Cancelado</h1>
                  <v-snackbar v-model="enviado" :timeout="5000">
                      Pedido cancelado exitosamente
                  </v-snackbar>
                </v-col>
            </v-row>
         </v-container>

</template>

<script>
import store from '../store'
import { mapGetters } from 'vuex'
import Vue from 'vue'

  export default {
    name: 'CancelarPedido',

    components: {
    },

    data: () => ({
      id: "",
      enviado: false,
      entityName: "pedidos"
    }),  

    computed: {
    },

    mounted: function(){
      this.id = this.$route.params.id;
      this.cancel();
    },

    methods: {
      cancel () {
        store.dispatch("updateEntity", {name: this.entityName, id: this.id, data:{cancelado: true}});
        this.enviado = true;
      },
    },
  }
</script>