import Vue from 'vue'
import Vuex from 'vuex'
import firebase from "firebase";
import { v4 as uuidv4 } from 'uuid';
import { firestore } from 'firebase-admin';

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    email: null,
    displayName: null,
    initialized: false,
    familiasProducto: [],
    productos: [],
    clientes: [],
    pedidos: [],
    references: {}
  },
  mutations: {
    setUser (state, user) {
      state.email = user.email;
      state.displayName = user.displayName;
      state.initialized = true;
    },
    set (state, entity) {
      console.log("Set: ", entity)
      let entry = entity.data;
      entry.id = entity.id;

      let index = -1
      
      if (state[entity.name].length > 0) {
        index = state[entity.name].findIndex(element => element === undefined ? false : (element.id === entity.id));
      }

      console.log("entity name: ", entity.name)
      if (index >= 0) {
        state[entity.name][index] = entry;
      } else {
        state[entity.name].push(entry);
      }
      
    },
    delete (state, entity) {
      if (state[entity.name] !== undefined) {
        console.log(state[entity.name])
        let index = state[entity.name].findIndex(element => element === undefined ? false : (element.id === entity.id));
        console.log("index", index)
        if (index > 0) {
          delete state[entity.name][index];
        } else {
          state[entity.name] = []
        }
      }
    },
    setReference (state, reference) {
      console.log("setReference: ", reference)
      if (state.references[reference.name] === undefined) {
        state.references[reference.name] = {}
      }
      state.references[reference.name] = reference.target;
    },
  },
  getters: {
    loggedIn (state) {
      return state.email !== null
    },
    allFamiliasProducto: (state) => () => {
      return state.familiasProducto;
    },
    allProductos: (state) => () => {
      return state.productos;
    },
    allClientes: (state) => () => {
      return state.clientes;
    },
    isInitialized (state) {
      return state.initialized;
    }
  },
  actions: {
    fetchUser ({commit}, user) {
      console.log("user: ", user);
      if (user === null) {
        commit("setUser", {email: null, displayName: null})  
      } else {
        commit("setUser", {email: user.email, displayName: user.displayName})
      }
    },
    sync({commit}, entity){
      console.log("sync: ", entity);
      entity.snapshot.docChanges().forEach(function(change) {
        if (change.type === "added" || change.type === "modified") {
          commit("set", {name: entity.name, id: change.doc.id, data: change.doc.data()});  
        }
        if (change.type === "removed") {
          console.log("removed", change.doc.id)
          commit("delete", {name:entity.name, id: change.doc.id});
        }
      });
    },
    addReference ({commit}, reference) {
      commit("setReference", reference);
    },

    // acciones de ABM
    addEntity(context, entity){
      context.state.references[entity.name].doc(uuidv4()).set(entity.data)
    },
    updateEntity(context, entity){
      console.log("updateEntity: ", entity)
      console.log("entity.name: ", entity.name)
      console.log("references:", context.state.references)
      context.state.references[entity.name].doc(entity.id).update(entity.data)
    },
    deleteEntity(context, entity){
      context.state.references[entity.name].doc(entity.id).delete()
    },

    // inicialización
    initialize ({ commit }, firebase) {
      // Configuracion de firebase
      const firebaseConfig = {
        apiKey: "AIzaSyBUFFgq4RcuC7xpxWGETsbMIX0P6ZriQ4o",
        authDomain: "croute-adc91.firebaseapp.com",
        databaseURL: "https://croute-adc91-default-rtdb.firebaseio.com",
        projectId: "croute-adc91",
        storageBucket: "croute-adc91.appspot.com",
        messagingSenderId: "312421721629",
        appId: "1:312421721629:web:af1d1a8e527cf6bfa348f4",
        measurementId: "G-3BNJNEQQ14"
      };

      // inicializa la autenticación
      firebase.initializeApp(firebaseConfig);

      firebase.auth().onAuthStateChanged(user => {
        console.log("fetchUser", user)
        store.dispatch("fetchUser", user);
      });

      // referencia la base de datos de familias de productos
      const familliasProductoDB = firebase.firestore().collection("/familiasProducto");
      store.dispatch("addReference", {name: 'familiasProducto', target: familliasProductoDB});
      familliasProductoDB.onSnapshot(function(snapshot) {
        store.dispatch("sync", {name: 'familiasProducto', snapshot: snapshot});
      });

      // referencia la base de datos de productos
      const productosDB = firebase.firestore().collection("/productos");
      store.dispatch("addReference", {name: 'productos', target: productosDB});
      productosDB.onSnapshot(function(snapshot) {
        store.dispatch("sync", {name: 'productos', snapshot: snapshot});
      });

      // referencia la base de datos de clientes
      const clientesDB = firebase.firestore().collection("/clientes");
      store.dispatch("addReference", {name: 'clientes', target: clientesDB});
      clientesDB.onSnapshot(function(snapshot) {
        store.dispatch("sync", {name: 'clientes', snapshot: snapshot});
      });

      // referencia la base de datos de pedidos
      const pedidosDB = firebase.firestore().collection("/pedidos");
      store.dispatch("addReference", {name: 'pedidos', target: pedidosDB});
      pedidosDB.onSnapshot(function(snapshot) {
        store.dispatch("sync", {name: 'pedidos', snapshot: snapshot});
      });
    }
  },
  modules: {
  }
})

export default store;

store.dispatch('initialize', firebase)
